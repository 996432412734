import React                from "react";
import Store                from "Dashboard/Core/Store";
import Action               from "Dashboard/Core/Action";
import Navigate             from "Dashboard/Core/Navigate";
import Utils                from "Dashboard/Utils/Utils";
import useList              from "Dashboard/Hooks/List";

// Components
import ProviderLogView      from "./ProviderLogView";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import FilterList           from "Dashboard/Components/Filter/FilterList";
import FilterItem           from "Dashboard/Components/Filter/FilterItem";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";



/**
 * The Provider Log List
 * @returns {React.ReactElement}
 */
function ProviderLogList() {
    const { load, loadFilter } = useList("providerLog");

    const navigate = Navigate.useGoto();

    const data = Store.useState("providerLog");
    const { loading, canEdit, list, total, filters, sort, providers, actions } = data;

    // The Current State
    const [ action, elemID, startAction, endAction ] = Action.useActionID();


    // Handles the Action
    const handleAction = async (action, elemID) => {
        if (action.isOrder) {
            const orderID = Utils.getValue(list, "logID", elemID, "orderID");
            navigate("ORDERS", orderID);
        } else {
            startAction(action, elemID);
        }
    };

    // Returns true if it can't goto the Order
    const cantOrder = (elemID) => {
        return !Utils.getValue(list, "logID", elemID, "orderID");
    };


    // Do the Render
    return <Main>
        <Header message="PROVIDERS_LOG_NAME" icon="provider-log" />
        <Content>
            <FilterList onFilter={loadFilter} values={filters}>
                <FilterItem
                    name="search"
                    label="GENERAL_SEARCH"
                    icon="search"
                    hasClear
                />
                <FilterItem
                    type="select"
                    name="provider"
                    label="PROVIDERS_LOG_PROVIDER"
                    options={providers}
                />
                <FilterItem
                    type="select"
                    name="action"
                    label="PROVIDERS_LOG_ACTION"
                    options={actions}
                />
                <FilterItem
                    type="date"
                    name="fromDate"
                    label="GENERAL_FROM_DATE"
                    hasClear
                />
                <FilterItem
                    type="date"
                    name="toDate"
                    label="GENERAL_TO_DATE"
                    hasClear
                />
            </FilterList>

            <Table
                fetch={load}
                sort={sort}
                none="PROVIDERS_LOG_NONE_AVAILABLE"
                isLoading={loading}
                hasFilter
            >
                <TableHead>
                    <TableHeader field="createdTime" message="GENERAL_TIME"           maxWidth="180" />
                    <TableHeader field="submitType"  message="GENERAL_TYPE"           />
                    <TableHeader field="provider"    message="PROVIDERS_LOG_PROVIDER" />
                    <TableHeader field="action"      message="PROVIDERS_LOG_ACTION"   />
                    <TableHeader field="orderID"     message="ORDERS_SINGULAR"        />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.logID} elemID={elem.logID}>
                        <TableCell message={elem.createdDate} />
                        <TableCell message={elem.submitType}  className={elem.errorClass} />
                        <TableCell message={elem.provider}    />
                        <TableCell message={elem.action}      />
                        <TableCell message={elem.orderText}   />
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={handleAction} canEdit={canEdit}>
                    <TableAction action="VIEW"  message="PROVIDERS_LOG_VIEW_TITLE" />
                    <TableAction action="ORDER" message="ORDERS_VIEW_TITLE"        hide={cantOrder} />
                </TableActionList>
            </Table>
        </Content>

        <ProviderLogView
            open={action.isVCE}
            elemID={elemID}
            onClose={endAction}
        />
    </Main>;
}

export default ProviderLogList;
