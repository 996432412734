import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Client Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ClientEdit(props) {
    const { open, elemID, onClose, onSubmit } = props;

    const { editElem } = Store.useAction("client");


    // The Initial Data
    const initialData = {
        clientID          : 0,
        firstName         : "",
        lastName          : "",
        email             : "",
        phone             : "",
        dni               : "",
        birthDate         : "",
        sex               : "",
        commune           : "",
        location          : "",
        addressAdditional : "",
        city              : "",
        latitude          : "",
        longitude         : "",
        confirmedTerms    : 0,
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("client", initialData, editElem, onSubmit);

    // Load the Data
    const { loading } = useDialog("client", open, elemID, null, setElem);


    // Do the Render
    return <EditDialog
        open={open}
        icon="client"
        title={elemID ? "CLIENTS_EDIT_TITLE" : "CLIENTS_CREATE_TITLE"}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <Columns>
            <InputField
                name="firstName"
                label="GENERAL_FIRST_NAME"
                value={data.firstName}
                error={errors.firstName}
                onChange={handleChange}
                isRequired
            />
            <InputField
                name="lastName"
                label="GENERAL_LAST_NAME"
                value={data.lastName}
                error={errors.lastName}
                onChange={handleChange}
                isRequired
            />

            <InputField
                type="email"
                name="email"
                label="GENERAL_EMAIL"
                value={data.email}
                error={errors.email}
                onChange={handleChange}
                isRequired
            />
            <InputField
                name="phone"
                label="GENERAL_PHONE"
                value={data.phone}
                error={errors.phone}
                onChange={handleChange}
            />

            <InputField
                name="dni"
                label="CLIENTS_DNI"
                value={data.dni}
                error={errors.dni}
                onChange={handleChange}
            />
            <InputField
                type="date"
                name="birthDate"
                label="CLIENTS_BIRTH_DATE"
                value={data.birthDate}
                error={errors.birthDate}
                onChange={handleChange}
            />

            <InputField
                name="sex"
                label="CLIENTS_SEX"
                value={data.sex}
                error={errors.sex}
                onChange={handleChange}
            />
            <InputField
                name="commune"
                label="GENERAL_COMMUNE"
                value={data.commune}
                error={errors.commune}
                onChange={handleChange}
            />
            <InputField
                className="columns-double"
                name="location"
                label="GENERAL_LOCATION"
                value={data.location}
                error={errors.location}
                onChange={handleChange}
                isRequired
            />
            <InputField
                name="addressAdditional"
                label="GENERAL_ADDRESS_ADDITIONAL"
                value={data.addressAdditional}
                error={errors.addressAdditional}
                onChange={handleChange}
                isRequired
            />
            <InputField
                name="city"
                label="GENERAL_CITY"
                value={data.city}
                error={errors.city}
                onChange={handleChange}
            />
            <InputField
                name="latitude"
                label="GENERAL_LATITUDE"
                value={data.latitude}
                error={errors.latitude}
                onChange={handleChange}
            />
            <InputField
                name="longitude"
                label="GENERAL_LONGITUDE"
                value={data.longitude}
                error={errors.longitude}
                onChange={handleChange}
            />
        </Columns>

        <InputField
            type="toggle"
            name="confirmedTerms"
            label="CLIENTS_CONFIRMED_TERMS"
            value={data.confirmedTerms}
            onChange={handleChange}
            withBorder
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ClientEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
};

export default ClientEdit;
